body {
  background-color: var(--tg-theme-bg-color) !important;
}

.App {
  /* text-align: center; */
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  font-size: 1rem;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.Button {
  border: 2px solid;
  border-image: linear-gradient(66deg, #74FF74 0%, #ffffff 50%, #D030FF 100%);
  border-image-slice: 1;
  background-color: var(--tg-theme-text-color);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  color: var(--tg-theme-bg-color) !important;
}

.Button:hover {
  border: 2px solid;
  border-image: linear-gradient(66deg, #74FF74 100%, #ffffff 101%, #D030FF 102%);
  border-image-slice: 1;
  transition: .5s ease-in-out;
}

.h5 {
  font-size: 1rem !important;
}

.seed-card-pill {
  /* background-color: #040403; */
  position: absolute;
}
.pill-background {
  background: rgb(63,94,251);
  background: -moz-radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
  background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3f5efb",endColorstr="#fc466b",GradientType=1);
}
.card-body {
  padding: 0.5em !important;
}

.card, .list-group-item {
  background-color: var(--tg-theme-bg-color) !important;
  color: var(--tg-theme-text-color) !important;
  border-color: var(--tg-theme-text-color) !important;
}

.badge {
  padding: 0.25em 0.40em !important;
  font-size: .9em !important;
}

.remove-btn {
  margin-right: 0.5rem;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
